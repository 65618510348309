import { TitleCasePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { HtmlMarkdownService } from "@hub-services/html-markdown.service";

@Pipe({name: 'historyDetailsFormat'})
export class HistoryDetailsFormatPipe implements PipeTransform {

    constructor(private htmlMarkdownService: HtmlMarkdownService) {}

    transform(allChanges: any) {
        const titleCasePipe = new TitleCasePipe();
        let allChangeArrray: string[] = Array();
        for(const change of allChanges){
            change.changes = this.convertMarkdownToHtml(change.changes);
            let itemType : string = change.item_type;
            if(itemType && itemType === 'SeatCharacteristic'){
                let arr: string[] = Array();
                this.keyValConverter(change.changes).forEach(element => {
                    arr.push(element.val);
                });
                allChangeArrray.push("Seat Characteristics: <br/>" + arr.join(" | ")+"<br/>");
            }
            else{
                let output = "";
                for(let keyVal of this.keyValConverter(change.changes)){
                let concatenatedValues = this.concatenateValues(keyVal.val, keyVal.key, change.action);
                if(concatenatedValues.length > 0){
                    let prefix = "";
                    let lowerCaseKey = keyVal.key.toLowerCase();
                    if(lowerCaseKey === 'position'){
                        prefix = itemType+' ';
                    }
                    else if((lowerCaseKey === 'starts at')||lowerCaseKey === 'ends at'){
                        prefix = 'Display Date ';
                    }
                    else if(lowerCaseKey === 'status'){
                        concatenatedValues = titleCasePipe.transform(concatenatedValues);
                    }
                    else if(lowerCaseKey === 'rule'){
                        prefix = 'Targeting ';
                    }
                    output = output + prefix + titleCasePipe.transform(keyVal.key) +' : '+concatenatedValues +"<br/>";
                }
                }
                allChangeArrray.push(output);
            }
    }
    return allChangeArrray.join("");
}
    concatenateValues(values?: any, key?: string, action?:string): string{
        let arr: string[] = Array();
        for (var val of values) {
            if(key?.toLowerCase() === 'caption' && val){
                val = val.replace(/[{}]/g, "");
            }
            if(action === 'update'){
                if((val === null) || (val.length === 0)){
                    arr.push('None');
                }
                else{
                    arr.push(val);
                }
            }
            else{
                if(val){
                    arr.push(val);
                }
            }
        }
        if(key){
            let lowerCaseKey = key.toLowerCase();
            if ((lowerCaseKey==='category') || (lowerCaseKey==='rule') || (lowerCaseKey==='brand')){
                return arr.join("");  
              }
        } 
          return arr.join(" -> ");
    }

    keyValConverter(value? : {[key:string]:string})
    {
        return Object.keys(value).map(key => ({key:key, val:value[key]}));
        
    }

    private convertMarkdownToHtml(changes: any): any {
        Object.keys(changes).forEach(key => {
            if (key === 'description_md') {
                changes.description_md.forEach((description_md, i) => {
                    if (description_md) changes.description_md[i] = this.htmlMarkdownService.parseMarkdownToHtml(description_md)
                })
            
            } else if (key === 'caption_md') {
                let nonDefaultLanguageCodes = [];
                changes.caption_md.reverse().forEach((captionData, i) => {
                    if (captionData) {
                        captionData = JSON.parse(captionData);
                        let captionDataKeys = Object.keys(captionData);
                        
                        // caption_md[0] representes the previous caption_md value
                        // this captionData does not contain language codes that were previously empty and then changed,
                        // add the missing language codes to the captionData
                        let missingLanguageCodes = nonDefaultLanguageCodes.filter(languageCode => !captionDataKeys.includes(languageCode))
                        missingLanguageCodes.forEach(languageCode => {
                            captionData[languageCode] = '';
                        })
                        captionDataKeys.push(...missingLanguageCodes);

                        let captionDataAsString = '';
                        captionDataKeys.forEach(key => {
                            if (!key.includes('default')) {
                                if (i === 0) {
                                    nonDefaultLanguageCodes.push(key)
                                }
                                if (captionData[key] === '') captionData[key] = 'None';
                                captionData[key] = this.htmlMarkdownService.parseMarkdownToHtml(captionData[key]);
                                captionDataAsString += key + ': ' + captionData[key];
                            }
                        });
                        
                        changes.caption_md[i] = captionDataAsString;
                    }
                })
                changes.caption_md.reverse();
            }
        })
        return changes;

    }
}
