import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { MediaFile } from '../gallery/media-file.model';
import { MediaType } from '../uas/editing/media/media-type.enum';
import { Observable, of, Subject } from 'rxjs';
import * as slug from 'slug';
import { FileDimentions } from '../gallery/legacy-common-components/file-dimentions.model';
import { Store } from '@ngrx/store';
import * as MessagesActions from '@hub-store/messages/messages.actions';
import { FileItem } from '@atpco/hub-ui/lib/file-upload/file-upload.component';
import { FileDimensions } from '../gallery/file-dimensions.model';


@Injectable({ providedIn: 'root' })
export class MediaFileService {
  path: string = '/api';
  constructor(
    private apiService: ApiService,
    private store: Store,
  ) { }

  getMediaFiles(partnerId: number, mediaType: MediaType): Observable<MediaFile[]> {
    return this.apiService.get(`${this.path}/v2/airlines/${partnerId}/${mediaType}s`).pipe(catchError(() => of([])));
  }

  updateMediaFile(partnerId: number, mediaFile: Partial<MediaFile>, mediaType: MediaType) {
    return this.apiService.put(`${this.path}/v2/airlines/${partnerId}/${mediaType}s/${mediaFile.id}`, {
      photo: {
        name: this.sanitizeFileName(mediaFile.name),
        upa_category_ids: mediaFile.upa_category_ids,
        subfleet_ids: mediaFile.subfleet_ids,
        generic_cabin_ids: mediaFile.generic_cabin_ids,
        airport_code: mediaFile.airport_code,
        alt: mediaFile.alt,
        expiration_date: mediaFile.expiration_date
      }
    });
  }

  updateMediaType(partnerId: number, photoId: number, mediaType: MediaType) {
    return this.apiService.put(`${this.path}/v2/airlines/${partnerId}/photos/${photoId}`, {
      photo: {
        photo_id: photoId,
        media_type: mediaType,
      }
    });
  }

  deleteMediaFile(photoId: number, partnerId: number) {
    return this.apiService.delete(`${this.path}/v2/airlines/${partnerId}/photos/${photoId}`);
  }

  uploadMediaFile(partnerId: number, file: any, dimentions: FileDimentions, mediaType: MediaType) {
    const formData = new FormData();
    formData.append('photo[file]', file, this.sanitizeFileName(file.name));
    formData.append('photo[width]', dimentions.width.toString());
    formData.append('photo[height]', dimentions.height.toString());
    formData.append('photo[media_type]', mediaType);
    return this.apiService
      .postForm(`${this.path}/v2/airlines/${partnerId}/photos`, formData);
  }

  uploadMediaFileReplace(partnerId: number, fileId: number, file: any, dimentions: FileDimentions, mediaType: MediaType) {
    const formData = new FormData();
    formData.append('photo[file]', file, this.sanitizeFileName(file.name));
    formData.append('photo[width]', dimentions.width.toString());
    formData.append('photo[height]', dimentions.height.toString());
    formData.append('photo[media_type]', mediaType);

    return this.apiService
      .putForm(`${this.path}/v2/airlines/${partnerId}/photos/${fileId}`, formData);
  }

  getMediaFileUploads(partnerId: number, mediaType: MediaType) {
    return this.apiService.get(`${this.path}/airlines/${partnerId}/photo_uploads?media_type=${mediaType}`);
  }

  getMediaFileUploadById(partnerId: number, id: number) {
    return this.apiService.get(`${this.path}/v2/airlines/${partnerId}/photos/${id}`);
  }

  getMediaFileById(id: number, mediaType: MediaType): Observable<MediaFile> {
    return this.apiService.get(`${this.path}/photos/${id}`);
  }

  deleteMediaFileUpload(id: number, mediaType: MediaType) {
    return this.apiService.delete(`${this.path}/photo_uploads/${id}?media_type=${mediaType}`);
  }

  /**
   * Transform file names into lower-case ASCII-chars only
   */
  sanitizeFileName(name: string): string {
    const nameTokens = name.split('.');
    let ext = '';
    // use last element as file extension if it has one
    if (nameTokens.length > 1) {
      ext = '.' + nameTokens.pop();
    }
    return slug(nameTokens.join('').replace(/\_/g, '-')) + ext;
  }

  uploadPhotoMediaFiles(mediaFiles: FileItem[], partnerId: number, mediaType: MediaType, photos?: any[]): Observable<{uploadedMedia: any[], mediaType: MediaType}> {
    let uploadComplete$ = new Subject<{uploadedMedia: any[], mediaType: MediaType}>();
    let uploadedMedia = [];
    mediaFiles.forEach(fileItem => {
      this.validateDimensions(fileItem.file, (dimensions: FileDimentions) => {
        if (dimensions) {
          this.uploadMediaFile(partnerId, fileItem.file, dimensions, mediaType).subscribe((response) => {
            !!photos && (photos[photos.findIndex(photo => photo.isLoading)] = response);
            uploadedMedia.push(response);
            if (uploadedMedia.length === mediaFiles.length) {
              uploadComplete$.next({uploadedMedia: uploadedMedia, mediaType: mediaType});
              this.store.dispatch(MessagesActions.setAppSnackbar({message: 'Upload Successful'}));
            }
          });
        } else {
          !!photos && photos.splice(0, 1);
          this.store.dispatch(MessagesActions.createErrorMessage({
            errorText: 'File is not valid.'
          }));
          uploadComplete$.error('File is not valid.');
        }
      });
    });
    return uploadComplete$;
  }

  validateDimensions(file: any, callback: any): any {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      window.URL.revokeObjectURL(img.src);
      if (width > 10 && height > 10) {
        callback({ width, height });
      } else {
        callback(false);
      }
    };
    img.onerror = function () {
      window.URL.revokeObjectURL(img.src);
      callback(true);
    };
  }

  replaceFile(file: any, partnerId: number, mediaType: MediaType, photos: any[], selectedPhoto: any) {
    this.validateDimensions(file, (dimensions: FileDimentions) => {
      if (dimensions) {
        let replacedPhoto = selectedPhoto;
        this.uploadMediaFileReplace(partnerId, selectedPhoto.id, file, dimensions, mediaType).subscribe({
          next: (response) => {
            photos[photos.findIndex(photo => photo.id === selectedPhoto.id)] = response;
          }, 
          error: (err) => {
            photos.unshift(replacedPhoto);
            this.store.dispatch(MessagesActions.createErrorMessage({
              errorText: 'File replace failed.'
            }));
          }
        })
      } else {
        this.store.dispatch(MessagesActions.createErrorMessage({
          errorText: 'File is not valid.'
        }));
      }
    }) 
  }
}
