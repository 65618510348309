import { Pipe, PipeTransform } from '@angular/core';
import { FilteredItems } from '../../gallery/legacy-common-components/photo-interfaces';

@Pipe({
  name: 'areFiltersApplied',
})
export class AreFiltersAppliedPipe implements PipeTransform {

  transform(filteredItems: FilteredItems): boolean {
    return filteredItems.categoryIds.length > 0 ||
      filteredItems.subfleetIds.length > 0 ||
      filteredItems.cabinIds.length > 0 ||
      filteredItems.airportCode?.length > 0 ||
      filteredItems.alt_text_values.length > 0
  }

}
