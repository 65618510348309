import { Injectable } from '@angular/core';
import * as Marked from 'marked';

@Injectable({providedIn: 'root'})
export class HtmlMarkdownService {

  public parseHtmlToMarkdown(html: string): string {
    if (!html) {
      return '';
    }
    let markdown = html;

    markdown = this.parseAll(markdown, 'strong', '**');
    markdown = this.parseAll(markdown, 'b', '**');
    markdown = this.parseAll(markdown, 'em', '*');
    markdown = this.parseAll(markdown, 'i', '*');
    markdown = this.parseAll(markdown, 's', '~~');
    markdown = this.htmlListToMarkdown(markdown, 'ol');
    markdown = this.htmlListToMarkdown(markdown, 'ul');
    // remove missed <li>, <ol> and <ul> tags
    markdown = markdown.replace(/<li>/g, '').replace(/<ol>/g, '').replace(/<ul>/g, '');
    markdown = this.setBreaksToHtml(markdown);

    markdown = markdown.replace(/&lt;script&gt;/g, '').replace(/&lt;\/script&gt;/g, '');
    markdown = markdown.replace(/&lt;script;/g, '');
    markdown = markdown.replace(/<h1>/g, '\n# ').replace(/<\/h1>/g, '');
    markdown = markdown.replace(/<h2>/g, '\n## ').replace(/<\/h2>/g, '');
    markdown = markdown.replace(/<h3>/g, '\n### ').replace(/<\/h3>/g, '');
    markdown = markdown.replace(/<h4>/g, '\n#### ').replace(/<\/h4>/g, '');
    markdown = markdown.replace(/<h5>/g, '\n##### ').replace(/<\/h5>/g, '');
    markdown = markdown.replace(/<h6>/g, '\n###### ').replace(/<\/h6>/g, '');
    markdown = markdown.replace(/<u>/g, '<ins>').replace(/<\/u>/g, '<\/ins>');
    markdown = markdown.replace(/<p><br><\/p>/g, '\n');
    markdown = markdown.replace(/<br>/g, '\n');
    markdown = markdown.replace(/<p>/g, '').replace(/<\/p>/g, '  \n');
    markdown = markdown.replace(/<div>/g, '').replace(/<\/div>/g, '  \n');
    markdown = markdown.replace(/<blockquote>/g, '> ').replace(/<\/blockquote>/g, '');
    markdown = markdown.replace(/<\/li>/g, '').replace(/<\/ol>/g, '').replace(/<\/ul>/g, '')

    // remove trailing newline characters
    if (markdown.slice(-1) === '\n') {
      markdown = markdown.slice(0, -1);
    }
    return markdown;
  }

  public parseMarkdownToHtml(markdown: string) {
    let html = this.setItalicSymbols(markdown);
    html = html.replace(/<ins>/g, '<u>').replace(/<\/ins>/g, '<\/u>');
    html = html.replace(/`/g, '<BACKTICK>'); //override converting backticks to <code> tag
    html = Marked.marked.parse(html)
    html = html.replace(/<BACKTICK>/g, '`');
    html = html.replace(/<br>/g, '<\/p><p>');
    return html;
  }

  private setItalicSymbols(markdown: string): string {
    let regex = /\__(.*?)\__/g;
    let match;
    do {
      if (match) {
        markdown = markdown.replace(match[0], '<i>' + match[1] + '</i>');
      }
      match = regex.exec(markdown);
    } while (match);
    return markdown;
  }

  private parseAll(html: string, htmlTag: string, markdownEquivalent: string) 
  {
    const regEx = new RegExp(`<\/?${htmlTag}>`, 'g');
    return html.replace(regEx, markdownEquivalent);
  }

  private htmlListToMarkdown(parsedHtml, listType: 'ol' | 'ul') {
    // check if the first list item is not closed and add starting tag
    if (parsedHtml.charAt(1) === '/' && parsedHtml.charAt(2) === 'l') {
      parsedHtml = parsedHtml.replace(/<\/li>/, `<${listType}>`);
    }
    const blankOrNewLine = (parsedHtml.charAt(1) === 'o' || parsedHtml.charAt(1) === 'u') ? '' : '\n';
    const getNextListRegEx = new RegExp(`<${listType}>.+?<\/${listType}>`);
    while (parsedHtml.match(getNextListRegEx) !== null) {
      let matchedList = parsedHtml.match(getNextListRegEx);
      let remainingHtml = parsedHtml.replace(getNextListRegEx, '');
      let outerList = '';
      // parse the last part of the list first
      if (remainingHtml !== '') {
        outerList = this.htmlListToMarkdown(remainingHtml, listType);
      }
      parsedHtml = parsedHtml.replace(remainingHtml, outerList);
      const parser = new DOMParser();
      const doc = parser.parseFromString(matchedList, 'text/html');
      const listItems = doc.querySelectorAll(listType);
      let completeList = '';
      for (let i = listItems.length - 1; i > -1; i--) {
          let list = '';
          listItems[i].childNodes.forEach((node, index) => {
            let identifier = listType === 'ol' ? `${index+1}.` : '-';
            if (node.nodeName === 'LI') {
              const textContent = node.childNodes[0].textContent.trim()
              if (textContent) {
                let tabs = "    ".repeat(i);
                if (index === 0) {
                  list = list + `${tabs}${identifier} ${textContent}`
                } else {
                  list = list + `\n${tabs}${identifier} ${textContent}`;
                }
              }
            }
          });
          if (i === listItems.length - 1) {
            completeList = list
          } else {
            completeList = `${list}\n` + completeList
          }
      };
      parsedHtml = parsedHtml.replace(
        getNextListRegEx,
        blankOrNewLine + completeList + "\n"
      );
    }
    return parsedHtml;
  }

  private setBreaksToHtml(html: string): string {
    if (html.charAt(1) === 'p') {
      html = html.replace(/<p>/, '').replace(/<\/p>/, '');
    }
    if (html.charAt(1) === 'h') {
      switch(html.charAt(2)) {
        case '1':
          html = html.replace(/<h1>/, '# ').replace(/<\/h1>/, '')
          break;
        case '2':
          html = html.replace(/<h2>/, '# ').replace(/<\/h2>/, '')
          break;
        case '3':
          html = html.replace(/<h3>/, '# ').replace(/<\/h3>/, '')
          break;
        case '4':
          html = html.replace(/<h4>/, '# ').replace(/<\/h4>/, '')
          break;
        case '5':
          html = html.replace(/<h5>/, '# ').replace(/<\/h5>/, '')
          break;
        case '6':
          html = html.replace(/<h6>/, '# ').replace(/<\/h6>/, '')
          break;
      }
    }
    html = html.replace(/<p>/g, '  \n').replace(/<\/p>/g, '');
    return html;
  }
}