import { createAction, props } from '@ngrx/store';
import { MediaFile } from '../../gallery/legacy-common-components/media-file.model';
import { MediaType } from '../../uas/editing/media/media-type.enum';
import { HubMediaState } from './media.reducer';

export const setMediaData = createAction(
  '[Media] Set media data',
  props<{ data: HubMediaState }>()
);

export const resetMediaData = createAction(
  '[Media] Reset media data',
);

export const addNewMediaFileInStore = createAction(
  '[Media] Add new media file in store',
  props<{ item: MediaFile }>()
);
